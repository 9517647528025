<template>
  <div class="routerview">
    <iframe class="iframe-slide" src="/slides_iframe_db_chap02"></iframe>
  </div>
</template>


<script>
    export default {
        name: "slidesdb_chap02_en"
    }
</script>


<style scoped src="@/assets/css/globalen.css">
</style>
