<template>
  <div class="routerview">
    <el-card class="box-card">
        <template #header>
            <div class="card-header"
                 v-for="item in headers">
              {{item[3]}}
            </div>
        </template>
        <div class="card-body" v-for="item in research">{{item.research}}</div>
    </el-card>
  </div>
</template>


<script>
    import config from '../config.js';
    import axios from 'axios'

    export default {
        name: "Reaserch_en",
        created() {
            const _this =this;
            let server = config.apiser;
            axios.get(server+'/homepage/research_eng').then(res =>{
                _this.research = res.data
            })
        },
        data(){
            return {
                research: null,
                includeResearch: null,
                excudeResearch: null,
                headers:[
                         {0: 'Profile'},
                         {1: 'Education'},
                         {2: 'Experiences'}, 
                         {3: 'Projects'}, 
                         {4: 'Publications'}, 
                         {5: 'Awards'}, 
                         {6: 'Courses'}, 
                         {7: 'Members'} 
                        ],
            }
        }
    }
</script>


<style scoped src="@/assets/css/globalen.css">
</style>
