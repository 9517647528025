<template>
    <div class="wholeview">
        <el-container>
            <el-header>
                <el-menu 
                  router mode="horizontal" 
                  background-color="#395959" text-color="#ffffff" active-text-color="#8FB8B7" >
                    <el-sub-menu :index="'1'" class="menuen">
                        <template #title>
                          <span class="menuen-text">
                            {{$router.options.routes[1].name}}
                          </span> 
                        </template>
                        <el-menu-item v-for="(sub_item,sub_index) in $router.options.routes[1].children" :index="sub_item.path">
                            <template #title class="submenuen">
                              <span class="submenuen-text">
                               {{sub_item.name}}
                              </span>
                            </template>
                        </el-menu-item>
                    </el-sub-menu>

                    <el-sub-menu :index="'2'" class="menuen">
                        <template #title>
                          <span class="menuen-text">
                            {{$router.options.routes[3].name}}
                          </span>      
                        </template>

                        <span v-for="(sub_item, sub_index) in $router.options.routes[3].children">

                          <span v-if="sub_item.path == '/slidesdb_en'">
                            <el-sub-menu :index="sub_item.path" class="menuen">
                                <template #title>
                                  <span class="menuen-text">
                                    {{sub_item.name}}
                                  </span>
                                </template>

                                <span v-for="(subsub_item, subsub_index) in $router.options.routes[3].children">
                                <span v-if="subsub_index > 1 && subsub_index < 11">
                                <el-menu-item :index="subsub_item.path">
                                    <template #title class="submenuen">
                                      <span class="submenuen-text">
                                        {{subsub_item.name}}
                                      </span>
                                    </template>
                                </el-menu-item>
                                </span>
                                </span>
                            </el-sub-menu>
                          </span>

                          <span v-else>

			    <!--
                            <span v-if="sub_index < 2">
			      <el-menu-item :index="sub_item.path">
				<template #title class="submenuen">
				  <span class="submenuen-text">
				    {{sub_item.name}}
				  </span>      
				</template>
		              </el-menu-item>
                            </span>
                             -->
				  <span v-if="sub_item.path == '/slidestc_en'">
				    <el-sub-menu :index="sub_item.path" class="menuen">
					<template #title>
					  <span class="menuen-text">
					    {{sub_item.name}}
					  </span>
					</template>

					<span v-for="(subsub_item, subsub_index) in $router.options.routes[3].children">
					<span v-if="subsub_index > 10 && subsub_index < 14">
					<el-menu-item :index="subsub_item.path">
					    <template #title class="submenuen">
					      <span class="submenuen-text">
						{{subsub_item.name}}
					      </span>
					    </template>
					</el-menu-item>
					</span>
					</span>
				    </el-sub-menu>
				  </span>

                          </span>
                      </span>
                    </el-sub-menu>

                    <el-link class="menucn" 
                             :underline="false" 
                             href="/"
                             v-for="item in linkcn" 
                    >
                      {{item}}
                    </el-link>
                </el-menu>
            </el-header>

            <el-main>
                <router-view></router-view>
            </el-main>

            <el-footer height="30px">
                <el-row justify="center">
                    <img :src="imgfooterlogo" style="width:30px;height: 30px"/>
                    <span class="copyright" style="line-height: 30px" v-for="item in copyrightinfo">
                      &nbsp; {{item}}
                    </span>
                </el-row>
                <el-row justify="center">
                  <span class="copyright" style="line-height: 30px" v-for="item in update">
                    {{item}}
                  </span>
                </el-row>
            </el-footer>
        </el-container>
    </div>
</template>


<script>
    export default {
        name: "Slidesview_en",
        data(){
            return{
              linkcn: ['中文'],
              imgfooterlogo: require('../assets/imgs/footer_logo.png'),
              copyrightinfo: ['Copyright © 2008-2024 Yu, Fajiang; TrustCute All Rights Reserved'],
              update: ['last update in 2024.04'],
            }
        }
    }
</script>


<style scoped src="@/assets/css/globalen.css">
</style>
