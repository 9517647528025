<template>
  <div class="routerview">
    <el-card class="box-card">
        <template #header>
            <div class="card-header"
                 v-for="item in headers">
              {{item[4]}}
            </div>
        </template>
        <div class="card-body" v-for="item in publications_paper">{{item.publication}}</div>
    </el-card>
    <div style="height: 20px"/>

    <el-card class="box-card">
        <template #header>
            <div class="card-header"
                 v-for="item in headers">
              {{item[5]}}
            </div>
        </template>
        <div class="card-body" v-for="item in publications_other">{{item.publication}}</div>
    </el-card>
  </div>
</template>


<script>
    import config from '../config.js';
    import axios from "axios";

    export default {
        name: "Publications_en",
        created() {
            const _this =this;
            let server = config.apiser;
            axios.get(server+'/homepage/paperPublication_eng').then(res =>{
                _this.publications_paper = res.data
            })
            axios.get(server+'/homepage/otherPublication_eng').then(res =>{
                _this.publications_other = res.data
            })
        },
        data(){
            return {
                publications_paper:null,
                publications_other:null,
                headers:[
                         {0: 'Profile'},
                         {1: 'Education'},
                         {2: 'Experiences'}, 
                         {3: 'Projects'}, 
                         {4: 'Publications'}, 
                         {5: 'Awards'}, 
                         {6: 'Courses'}, 
                         {7: 'Members'} 
                        ],
            }
        }
    }
</script>


<style scoped src="@/assets/css/globalen.css">
</style>
