<template>
  <div class="wholeview">
    <el-container>
      <el-header>
        <el-menu 
          router mode="horizontal" 
          background-color="#395959" text-color="#ffffff" active-text-color="#8FB8B7">
          <el-sub-menu :index="'1'" class="menuen">
            <template #title>
              <span class="menuen-text">
                {{$router.options.routes[1].name}}
              </span>
            </template>
            <el-menu-item 
              class="submenuen"
              v-for="(sub_item,sub_index) in $router.options.routes[1].children" :index="sub_item.path">
              <template #title>
               <span class="submenuen-text">
                  {{sub_item.name}}
                </span>
              </template>
            </el-menu-item>
          </el-sub-menu>
          <el-link class="menucn" 
                   :underline="false" 
                   href="/"
                   v-for="item in linkcn" 
          >
            {{item}}
          </el-link>
        </el-menu>
      </el-header>

      <el-main>
        <router-view></router-view>
      </el-main>

      <el-footer height="30px"> 
          <el-row justify="center">
              <img :src="imgfooterlogo" style="width:30px;height: 30px"/>
              <span class="copyright" style="line-height: 30px" v-for="item in copyrightinfo">
                &nbsp; {{item}}
              </span>
          </el-row>
          <el-row justify="center">
              <span class="copyright" style="line-height: 30px" v-for="item in update">
                {{item}}
              </span>
          </el-row>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
    export default {
        name: "Homeview_en",
        data(){
            return{
              linkcn: ['中文'],
              imgfooterlogo: require('../assets/imgs/footer_logo.png'),
              copyrightinfo: ['Copyright © 2008-2024 Yu, Fajiang; TrustCute All Rights Reserved'],
              update: ['last update in 2024.04'],
            }
        }
    }
</script>


<style scoped  src="@/assets/css/globalen.css">
</style>
