<template>
  <div class="wholeview">
    <el-container>
      <el-header>
        <el-menu 
          router mode="horizontal" 
          background-color="#395959" text-color="#ffffff" active-text-color="#8FB8B7">
          <el-sub-menu :index="'1'" class="menucn">
            <template #title>
              <span class="menucn-text">{{$router.options.routes[0].name}}</span>
            </template>
            <el-menu-item 
              class="submenucn"
              v-for="(sub_item,sub_index) in $router.options.routes[0].children" :index="sub_item.path">
              <template #title class="submenucn">
                <span class="submenucn-text">
                  {{sub_item.name}}
                </span>
              </template>
            </el-menu-item>
          </el-sub-menu>
          <el-link class="menuen" 
                   :underline="false" 
                   href=/home_en
                   v-for="item in linken" 
          >
            {{item}}
          </el-link>
        </el-menu>
      </el-header>

      <el-main>
        <router-view></router-view>
      </el-main>

      <el-footer height="30px"> 
          <el-row justify="center">
              <img :src="imgfooterlogo" style="width:30px;height: 30px"/>
              <span class="copyright" style="line-height: 30px" v-for="item in copyrightinfo">
                &nbsp; {{item}}
              </span>
              <!--
              <span class="copyright" style="line-height: 30px" v-for="item in filerecord">
                &nbsp; <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none;"> {{item}} </a>
              </span>
                -->
          </el-row>
          <el-row justify="center">
              <span class="copyright" style="line-height: 30px" v-for="item in update">
                {{item}}
              </span>
          </el-row>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
    export default {
        name: "Homeview",
        data(){
            return{
              linken: ['English'],
              imgfooterlogo: require('../assets/imgs/footer_logo.png'),
              copyrightinfo: ['Copyright © 2008-2024 至余信安; TrustCute 版权所有'],
              filerecord: ['鄂ICP备11005445'],
              update: ['last update in 2024.04'],
            }
        }
    }
</script>


<style scoped src='@/assets/css/globalcn.css'>
</style>
