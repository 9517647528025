<template>
  <div class="routerview">
    <el-card class="box-card">
        <template #header>
            <div class="card-header"
                 v-for="item in headers">
               {{item[4]}}
            </div>
        </template>
        <div class="card-body" v-for="item in publications_paper">{{item.publication}}</div>
    </el-card>
    <div style="height: 20px"/>

    <el-card class="box-card">
        <template #header>
            <div class="card-header"
                 v-for="item in headers">
               {{item[5]}}
            </div>
        </template>
        <div class="card-body" v-for="item in publications_other">{{item.publication}}</div>
    </el-card>
  </div>
</template>


<script>
    import config from '../config.js';
    import axios from "axios";

    export default {
        name: "Publications",
        created() {
            const _this =this;
            let server = config.apiser;
            axios.get(server+'/homepage/paperPublication').then(res =>{
                _this.publications_paper = res.data
            })
            axios.get(server+'/homepage/otherPublication').then(res =>{
                _this.publications_other = res.data
            })
        },
        data(){
            return {
                headers:[
                         {0: '概况'},
                         {1: '教育经历'},
                         {2: '工作经历'}, 
                         {3: '项目'}, 
                         {4: '论著'}, 
                         {5: '奖励'}, 
                         {6: '课程'}, 
                         {7: '成员'} 
                        ],
                publications_paper:null,
                publications_other:null
            }
        }
    }
</script>


<style scoped src='@/assets/css/globalcn.css'>
</style>
