<template>
    <div class="wholeview">
        <el-container>
            <el-header>
                   <!-- 
                  :default-active="activeIndex" 
                  @select="handleSelect" 
                     -->
                <el-menu
                  router mode="horizontal" 
                  background-color="#395959" text-color="#ffffff" active-text-color="#8FB8B7" >
                    <el-sub-menu :index="'1'" class="menucn">
                        <template #title>
                          <span class="menucn-text">
                            {{$router.options.routes[0].name}}
                          </span>
                        </template>
                        <el-menu-item v-for="(sub_item,sub_index) in $router.options.routes[0].children" :index="sub_item.path">
                            <template #title class="submenucn">
                              <span class="submenucn-text">
                                {{sub_item.name}}
                              </span>
                            </template>
                        </el-menu-item>
                    </el-sub-menu>

                    <el-sub-menu :index="'3'" class="menucn">
                        <template #title>
                          <span class="menucn-text">
                            {{$router.options.routes[2].name}}
                          </span>
                        </template>

			<span v-for="(sub_item, sub_index) in $router.options.routes[2].children">

			  <span v-if="sub_item.path == '/slidesdb'">
			    <el-sub-menu :index="sub_item.path" class="menucn">
				<template #title>
				  <span class="menucn-text">
			            {{sub_item.name}}		
				  </span>
				</template>

			        <span v-for="(subsub_item, subsub_index) in $router.options.routes[2].children">
			        <span v-if="subsub_index > 1 && subsub_index < 11">
				<el-menu-item :index="subsub_item.path">
				    <template #title class="submenucn">
				      <span class="submenucn-text">
					{{subsub_item.name}}
				      </span>
				    </template>
				</el-menu-item>
				</span>	
				</span>
                    	    </el-sub-menu>
                          </span>

			  <span v-else>

				  <span v-if="sub_item.path == '/slidestc'">
				    <el-sub-menu :index="sub_item.path" class="menucn">
					<template #title>
					  <span class="menucn-text">
					    {{sub_item.name}}		
					  </span>
					</template>

					<span v-for="(subsub_item, subsub_index) in $router.options.routes[2].children">
					<span v-if="subsub_index > 10 && subsub_index < 14">
					<el-menu-item :index="subsub_item.path">
					    <template #title class="submenucn">
					      <span class="submenucn-text">
						{{subsub_item.name}}
					      </span>
					    </template>
					</el-menu-item>
					</span>	
					</span>
				    </el-sub-menu>
				  </span>
                            
                            <!--
			    <span v-if="sub_index < 2">
			    <el-menu-item :index="sub_item.path">
			      <template #title class="submenucn">
			 	<span class="submenucn-text">
				  {{sub_item.name}}
				</span>
			      </template>
			    </el-menu-item>
                            </span>
                             -->
 
			  </span>
			</span>
                    </el-sub-menu>

                    <el-link class="menuen" 
                             :underline="false" 
                             href="/home_en"
                             v-for="item in linken" 
                    >
                      {{item}}
                    </el-link>
                </el-menu>
            </el-header>

            <el-main>
                <router-view></router-view>
            </el-main>

            <el-footer height="30px">
                <el-row justify="center">
                    <img :src="imgfooterlogo" style="width:30px;height: 30px"/>
                    <span class="copyright" style="line-height: 30px" v-for="item in copyrightinfo">
                      &nbsp; {{item}}
                    </span>
                    <!-- 
                    <span class="copyright" style="line-height: 30px" v-for="item in filerecord">
                      &nbsp; <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none;"> {{item}} </a>
                    </span>
                      -->
                </el-row>
                <el-row justify="center">
                   <span class="copyright" style="line-height: 30px" v-for="item in update">
                     {{item}}
                   </span>
                </el-row>
            </el-footer>

        </el-container>
    </div>
</template>


<script>
    import router from '../router/index.js';

    export default {
        name: "Slidesview_cn",
        data(){
            return{
              linken: ['English'],
              imgfooterlogo: require('../assets/imgs/footer_logo.png'),
              copyrightinfo: ['Copyright © 2008-2024 至余信安; TrustCute 版权所有'],
              filerecord: ['鄂ICP备11005445'],
              update: ['last update in 2024.04'],
            }
        },
       
        /*
        mounted() {
           console.log('router lists:', router.getRoutes());
        },
         */ 
    }
</script>


<style scoped src='@/assets/css/globalcn.css'>
</style>
