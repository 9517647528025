<template>
    <div class="routerview">
        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[0]}}
                </div>
            </template>
            <div>
                <div class="card-body-left">
                    <img :src="imgjohn" style="width: 140px; height: 196px;"/>
                    <div style="height: 20px"/>
                </div>
                <div class="card-body-right" id = "about_left">
                    <div class = "card-body-name" v-for="item in johnname">
                      {{item}}
                    </div>
                    <div style="height: 40px"/>
                    <div class="card-body-info" v-for="item in info">{{item}}</div>
                </div>
            </div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
               <div class="card-header"
                    v-for="item in headers">
                 {{item[1]}}
               </div>
            </template>
            <div class="card-body" v-for="item in educationExperience">{{item.experience}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
               <div class="card-header"
                    v-for="item in headers">
                 {{item[2]}}
               </div>
            </template>
            <div class="card-body" v-for="item in learningExperience">{{item.experience}}</div>
        </el-card>
        <div id="research" style="height: 20px"></div>

        <el-card class="box-card">
            <template #header>
               <div class="card-header"
                    v-for="item in headers">
                 {{item[3]}}
               </div>
            </template>
            <div class="card-body" v-for="item in research">{{item.research}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
               <div class="card-header"
                    v-for="item in headers">
                 {{item[4]}}
               </div>
            </template>
            <div class="card-body" v-for="item in publications_paper">{{item.publication}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
               <div class="card-header"
                    v-for="item in headers">
                 {{item[5]}}
               </div>
            </template>
            <div class="card-body" v-for="item in publications_other">{{item.publication}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
               <div class="card-header"
                    v-for="item in headers">
                 {{item[6]}}
               </div>
            </template>
            <div class="card-body" v-for="item in courses"> 
               <el-row>
                <el-link :underline="false" class="card-body-text"
                         :href="item.url">
                  {{item.name}}
                </el-link>
		<!--
                &nbsp;&nbsp;&nbsp;
                <el-link :underline="false" class="card-body-text"
                         :href="item.pdf">
                   print-pdf
                </el-link>
		 -->	
               </el-row>
            </div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
          <template #header>
            <div class="card-header"
                 v-for="item in headers">
              {{item[7]}}
            </div>
          </template>
          <div class="card-body-member">
            <el-row>
              <span v-for="item in ungraduate">{{item}}</span>
              <span v-for="item in members">&nbsp;{{item}}&nbsp;</span>
            </el-row>
            <div style="height: 10px"/>
            <el-row>
              <span v-for="item in postgraduate">{{item}}</span>
              <span v-for="item in members_graduate">&nbsp;{{item}}&nbsp;</span>
            </el-row>
          </div>
        </el-card>

    </div>
</template>


<script>
    import config from '../config.js';
    import axios from "axios";

    export default {
        name: "Experience_en",
        created() {
            const _this =this;
            let server = config.apiser;
            axios.get(server+'/homepage/educationExperience_eng').then(res =>{
                _this.educationExperience = res.data
            })
            axios.get(server+'/homepage/learningExperience_eng').then(res =>{
                _this.learningExperience = res.data
            })
            axios.get(server+'/homepage/research_eng').then(res =>{
                _this.research = res.data
            })
            axios.get(server+'/homepage/paperPublication_eng').then(res =>{
                _this.publications_paper = res.data
            })
            axios.get(server+'/homepage/otherPublication_eng').then(res =>{
                _this.publications_other = res.data
            })
            let width  = window.innerWidth
            if (width<=1300){
                let obj = document.getElementById("about_left")
                // console.log(obj)
            }
        },

        data(){
            return {
                educationExperience:null,
                learningExperience:null,
                research:null,
                includeResearch: null,
                excudeResearch: null,
                publications_paper:null,
                publications_other:null,
                headers:[
                         {0: 'Profile'},
                         {1: 'Education'},
                         {2: 'Experiences'}, 
                         {3: 'Projects'}, 
                         {4: 'Publications'}, 
                         {5: 'Awards'}, 
                         {6: 'Courses'}, 
                         {7: 'Members'} 
                        ],
                imgjohn: require('../assets/imgs/john.jpg'),
                johnname: ['Yu, Fajiang'],
                info: ['Associate Professor','School of Cyber Science and Engineering, Wuhan University, China','fjyu@whu.edu.cn'],
                courses:[
                         {name: "Database Management System and It's Security", 
                          url: '/slidesdb_en',
                          pdf: '/slides_iframe_db_chap01?print-pdf',
                         },
                         {name: 'Trusted Computing', 
                          url: '/slidestc_en',
                          pdf: '/slides_iframe_tc?print-pdf',
                         },
			 /*
                         {name: 'Advanced Database Security', 
                          url: null,
                          pdf: null,
                         },
                         {name: 'Interactive Tests', 
                          url: '/slidestest_en',
                          pdf: '/slides_iframe_test?print-pdf',
                         }
			  */	
                        ],
                ungraduate:['Now: '],
                postgraduate:['Graduated: '],
                members_graduate:
			[ 'Chen, Lie;  ',
			  'Hu, Jiuding;  ',
			  'Chen, Yuchi;  ',
			  'Jia, Yaomin;  ',
			  'Xu, Qing; ',
			  'Zheng, Shufei;  ',
			  'Liu, Nan; ', 
			  'Ye, Maolin;  ',
			  'Shen, Gan;  ',
			  'Yang, Yifu;  ',
			  'Xu, Xiaoli',
			],
                members:
			[
			  'Huang, Yanting;  ',
			  'Yi, Qingbin;  ', 
			  'Wang, Chaozhou;   ',  
			  'Deng, Yilin;   ',
        		  'Qiu, Lanqi',	  
			]
            }
        }, 
    }
</script>


<style scoped src="@/assets/css/globalen.css">
</style>
