<template>
    <div class="routerview">
        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[0]}}
                </div>
            </template>
            <div>
                <div class="card-body-left">
                    <img :src="imgjohn" style="width: 140px; height: 196px;"/>
                    <div style="height: 20px"/>
                </div>
                <div class="card-body-right" id = "about_left">
                    <div class = "card-body-name" v-for="item in johnname">
                      {{item}}
                    </div>
                    <div style="height: 40px"/>
                    <div class="card-body-info" v-for="item in info">
                      {{item}}
                    </div>
                </div>
            </div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[1]}}
                </div>
            </template>
            <div class="card-body" v-for="item in educationExperience">{{item.experience}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[2]}}
                </div>
            </template>
            <div class="card-body" v-for="item in learningExperience">{{item.experience}}</div>
        </el-card>
        <div id="research" style="height: 20px"></div>

        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[3]}}
                </div>
            </template>
            <div class="card-body" v-for="item in research">{{item.research}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[4]}}
                </div>
            </template>
            <div class="card-body" v-for="item in publications_paper">{{item.publication}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[5]}}
                </div>
            </template>
            <div class="card-body" v-for="item in publications_other">{{item.publication}}</div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
            <template #header>
                <div class="card-header"
                     v-for="item in headers">
                  {{item[6]}}
                </div>
            </template>
            <div class="card-body" v-for="item in courses"> 
               <el-row>
                <el-link :underline="false" class="card-body-text"
                         :href="item.url">
                  {{item.name}}
                </el-link>
		<!--
                &nbsp;&nbsp;&nbsp;
                <el-link :underline="false" class="card-body-text"
                         :href="item.pdf">
                   print-pdf
                </el-link>
		  -->	
               </el-row>
            </div>
        </el-card>
        <div style="height: 20px"/>

        <el-card class="box-card">
          <template #header>
            <div class="card-header"
                 v-for="item in headers">
              {{item[7]}}
            </div>
          </template>
          <div class="card-body-member">
            <el-row>
              <span v-for="item in ungraduate">{{item}}</span>
              <span v-for="item in members">&nbsp;{{item}}&nbsp;</span>
            </el-row>
            <div style="height: 10px"/>
            <el-row>
              <span v-for="item in postgraduate">{{item}}</span>
              <span v-for="item in members_graduate">&nbsp;{{item}}&nbsp;</span>
            </el-row>
          </div>
        </el-card>
    </div>
</template>


<script>
    import config from '../config.js';
    import axios from "axios";

    export default {
        name: "Experience",
        created() {
            const _this =this;
            let server = config.apiser;
            axios.get(server+'/homepage/educationExperience').then(res =>{
                _this.educationExperience = res.data
            })
            axios.get(server+'/homepage/learningExperience').then(res =>{
                _this.learningExperience = res.data
            })
            axios.get(server+'/homepage/research').then(res =>{
                _this.research = res.data
            })
            axios.get(server+'/homepage/paperPublication').then(res =>{
                _this.publications_paper = res.data
            })
            axios.get(server+'/homepage/otherPublication').then(res =>{
                _this.publications_other = res.data
            })
            let width  = window.innerWidth
            if (width<=1300){
                let obj = document.getElementById("about_left")
                // console.log(obj)
            }
        },

        data() {
            return {
                educationExperience:null,
                learningExperience:null,
                research:null,
                includeResearch: null,
                excudeResearch: null,
                publications_paper:null,
                publications_other:null,
                headers:[
                         {0: '概况'},
                         {1: '教育经历'},
                         {2: '工作经历'}, 
                         {3: '项目'}, 
                         {4: '论著'}, 
                         {5: '奖励'}, 
                         {6: '课程'}, 
                         {7: '成员'} 
                        ],
                imgjohn: require('../assets/imgs/john.jpg'),
                johnname: ['余发江'],
                info: ['副教授','武汉大学，国家网络安全学院','fjyu@whu.edu.cn'],
                courses:[
                         {name: '数据库系统及安全', 
                          url: '/slidesdb',
                          pdf: '/slides_iframe_db_chap01?print-pdf',
                         },
                         {name: '可信计算技术', 
                          url: '/slidestc',
                          pdf: '/slides_iframe_tc?print-pdf',
                         },
			 /*
                         {name: '高级数据库安全', 
                          url: null,
                          pdf: null,
                         },
                         {name: '交互测试', 
                          url: '/slidestest',
                          pdf: '/slides_iframe_test?print-pdf',
                         }
			  */	
                        ],
                ungraduate:['正在读：'],
                postgraduate:['已毕业：'],
                members_graduate:
			[
			  '陈列；',
			  '胡九鼎；',
			  '陈宇驰；',
			  '贾耀民；',
			  '徐庆；',
			  '郑淑菲；',
			  '刘楠; ',
			  '叶茂林；',
			  '申淦；',
			  '杨一夫；',
			  '徐晓丽',
			],
                members:[
			  '黄琰婷；',
			  '夷清斌；',
			  '王朝州；',
			  '邓伊琳；',
			  '邱兰琦',

			],
            }
        }
    }
</script>


<style scoped src="@/assets/css/globalcn.css">
</style>
