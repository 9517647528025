<template>
  <div class="routerview">
    <iframe class="iframe-slide" src="/slides_iframe_tc_02_bitlocker"></iframe>
  </div>
</template>


<script>
    export default {
        name: "slidestc_02_bitlocker_en"
    }
</script>


<style scoped src="@/assets/css/globalen.css">
</style>
