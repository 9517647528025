import { createRouter, createWebHistory } from 'vue-router'

import Homeview from '../viewscn/Homeview.vue'
import Experience from '../viewscn/Experience.vue'
import Research from '../viewscn/Research.vue'
import Publications from '../viewscn/Publications.vue'

import Homeview_en from "../viewsen/Homeview_en";
import Experience_en from "../viewsen/Experience_en";
import Research_en from "../viewsen/Research_en";
import Publications_en from "../viewsen/Publications_en";

import Slidesview_cn from "../viewsslidescn/Slidesview_cn";
import slidesdb_chap00 from "../viewsslidescn/slidesdb_chap00";
import slidesdb_chap01 from "../viewsslidescn/slidesdb_chap01";
import slidesdb_chap02 from "../viewsslidescn/slidesdb_chap02";
import slidesdb_chap03 from "../viewsslidescn/slidesdb_chap03";
import slidesdb_chap04 from "../viewsslidescn/slidesdb_chap04";
import slidesdb_chap05 from "../viewsslidescn/slidesdb_chap05";
import slidesdb_chap06 from "../viewsslidescn/slidesdb_chap06";
import slidesdb_chap07 from "../viewsslidescn/slidesdb_chap07";
import slidesdb_chap08 from "../viewsslidescn/slidesdb_chap08";
// import slidesdb_dm from "../viewsslidescn/slidesdb_dm";

import slidestc_00overview from "../viewsslidescn/slidestc_00overview";
import slidestc_01_secboot_signmodule from "../viewsslidescn/slidestc_01_secboot_signmodule";
import slidestc_02_bitlocker from "../viewsslidescn/slidestc_02_bitlocker";

// import slidestest from "../viewsslidescn/slidestest";

import slides_iframe_db_chap00 from "../viewsslidescn/slides_iframe_db_chap00";
import slides_iframe_db_chap01 from "../viewsslidescn/slides_iframe_db_chap01";
import slides_iframe_db_chap02 from "../viewsslidescn/slides_iframe_db_chap02";
import slides_iframe_db_chap03 from "../viewsslidescn/slides_iframe_db_chap03";
import slides_iframe_db_chap04 from "../viewsslidescn/slides_iframe_db_chap04";
import slides_iframe_db_chap05 from "../viewsslidescn/slides_iframe_db_chap05";
import slides_iframe_db_chap06 from "../viewsslidescn/slides_iframe_db_chap06";
import slides_iframe_db_chap07 from "../viewsslidescn/slides_iframe_db_chap07";
import slides_iframe_db_chap08 from "../viewsslidescn/slides_iframe_db_chap08";
// import slides_iframe_db_dm from "../viewsslidescn/slides_iframe_db_dm";

import slides_iframe_tc_00overview from "../viewsslidescn/slides_iframe_tc_00overview";
import slides_iframe_tc_01_secboot_signmodule from "../viewsslidescn/slides_iframe_tc_01_secboot_signmodule";
import slides_iframe_tc_02_bitlocker from "../viewsslidescn/slides_iframe_tc_02_bitlocker";

// import slides_iframe_test from "../viewsslidescn/slides_iframe_test";

import Slidesview_en from "../viewsslidesen/Slidesview_en";

import slidesdb_chap00_en from "../viewsslidesen/slidesdb_chap00_en";
import slidesdb_chap01_en from "../viewsslidesen/slidesdb_chap01_en";
import slidesdb_chap02_en from "../viewsslidesen/slidesdb_chap02_en";
import slidesdb_chap03_en from "../viewsslidesen/slidesdb_chap03_en";
import slidesdb_chap04_en from "../viewsslidesen/slidesdb_chap04_en";
import slidesdb_chap05_en from "../viewsslidesen/slidesdb_chap05_en";
import slidesdb_chap06_en from "../viewsslidesen/slidesdb_chap06_en";
import slidesdb_chap07_en from "../viewsslidesen/slidesdb_chap07_en";
import slidesdb_chap08_en from "../viewsslidesen/slidesdb_chap08_en";

import slidestc_00overview_en from "../viewsslidesen/slidestc_00overview_en";
import slidestc_01_secboot_signmodule_en from "../viewsslidesen/slidestc_01_secboot_signmodule_en";
import slidestc_02_bitlocker_en from "../viewsslidesen/slidestc_02_bitlocker_en";

// import slidestest_en from "../viewsslidesen/slidestest_en";


const routes = [
  {
    path: '/',
    name: '主页',
    component: Homeview,
    redirect: 'experience',
    children:[
      {
        path: '/experience',
        name: '个人经历',
        component: Experience
      },
      {
        path: '/research',
        name: '项目支持',
        component: Research
      },
      {
        path: '/publications',
        name: '论著获奖',
        component: Publications
      }
    ]
  },

  {
    path: '/home_en',
    name: 'Home',
    component: Homeview_en,
    redirect: 'experience_en',
    children:[
      {
        path: '/experience_en',
        name: 'Experiences',
        component: Experience_en
      },
      {
        path: '/research_en',
        name: 'Projects',
        component: Research_en
      },
      {
        path: '/publications_en',
        name: 'Publications',
        component: Publications_en
      }
    ]
  },

  {
    path: '/slides_cn',
    name: '课件',
    component: Slidesview_cn,
    redirect: 'slidesdb',
    children:[
      {
        path: '/slidesdb',
        name: '数据库系统',
        component: slidesdb_chap01,
      },
      {
        path: '/slidestc',
        name: '可信计算',
        component: slidestc_00overview
      },
      /*
      {
        path: '/slidestest',
        name: '交互测试',
        component: slidestest
      },
       */
      {
        path: '/slidesdb_chap00',
        name: '0. 数据库课程概况',
        component: slidesdb_chap00,
      },
      {
        path: '/slidesdb_chap01',
        name: '1. 数据库简介',
        component: slidesdb_chap01,
      },
      {
        path: '/slidesdb_chap02',
        name: '2. 关系模型与关系代数',
        component: slidesdb_chap02,
      },
      {
        path: '/slidesdb_chap03',
        name: '3. SQL语言',
        component: slidesdb_chap03,
      },
      {
        path: '/slidesdb_chap04',
        name: '4. 数据库设计',
        component: slidesdb_chap04,
      },
      {
        path: '/slidesdb_chap05',
        name: '5. 关系模式规范化',
        component: slidesdb_chap05,
      },
      {
        path: '/slidesdb_chap06',
        name: '6. 事务、并发与恢复',
        component: slidesdb_chap06,
      },
      {
        path: '/slidesdb_chap07',
        name: '7. NoSQL数据库',
        component: slidesdb_chap07,
      },
      {
        path: '/slidesdb_chap08',
        name: '8. 课程回顾',
        component: slidesdb_chap08,
      },
      /*
      {
        path: '/slidesdb_dm',
        name: '达梦数据库',
        component: slidesdb_dm,
      },
       */
      {
        path: '/slidestc_00overview',
        name: '0. 可信计算课程概况',
        component: slidestc_00overview,
      },
      {
        path: '/slidestc_01_secboot_signmodule',
        name: '1. 安全启动与内核签名',
        component: slidestc_01_secboot_signmodule,
      },
      {
        path: '/slidestc_02_bitlocker',
        name: '2. BitLocker硬盘数据加密',
        component: slidestc_02_bitlocker,
      },
    ]
  },

  {
    path: '/slides_en',
    name: 'Slides',
    component: Slidesview_en,
    redirect: 'slidesdb_en',
    children:[
      {
        path: '/slidesdb_en',
        name: 'Database',
        component: slidesdb_chap01_en
      },
      {
        path: '/slidestc_en',
        name: 'Trusted Computing',
        component: slidestc_00overview_en
      },
      /*
      {
        path: '/slidestest_en',
        name: 'Interactive Tests',
        component: slidestest_en
      },
       */ 
      {
        path: '/slidesdb_chap00_en',
        name: '0. Database Course Overview',
        component: slidesdb_chap00_en,
      },
      {
        path: '/slidesdb_chap01_en',
        name: '1. Database Introduction',
        component: slidesdb_chap01_en,
      },
      {
        path: '/slidesdb_chap02_en',
        name: '2. Relational Model and Relational Algebra',
        component: slidesdb_chap02_en,
      },
      {
        path: '/slidesdb_chap03_en',
        name: '3. SQL Language',
        component: slidesdb_chap03_en,
      },
      {
        path: '/slidesdb_chap04_en',
        name: '4. Database Design',
        component: slidesdb_chap04_en,
      },
      {
        path: '/slidesdb_chap05_en',
        name: '5. Normalization of Relational Schema',
        component: slidesdb_chap05_en,
      },
      {
        path: '/slidesdb_chap06_en',
        name: '6. Transaction, Concurrency and Recovery',
        component: slidesdb_chap06_en,
      },
      {
        path: '/slidesdb_chap07_en',
        name: '7. NoSQL Database',
        component: slidesdb_chap07_en,
      },
      {
        path: '/slidesdb_chap08_en',
        name: '8. Course Review',
        component: slidesdb_chap08_en,
      },

      {
        path: '/slidestc_00overview_en',
        name: '0. TC Course Overview',
        component: slidestc_00overview_en
      },
      {
        path: '/slidestc_01_secboot_signmodule_en',
        name: '1. Secure Boot and Signed Kernel Module',
        component: slidestc_01_secboot_signmodule_en,
      },
      {
        path: 'slidestc_02_bitlocker_en',
        name: '2. BitLocker Drive Encryption',
        component: slidestc_02_bitlocker_en,
      },
    ]
  },

  {
    path: '/slides_iframe_db_chap00',
    name: 'slides_iframe_db_chap00',
    component: slides_iframe_db_chap00,
  },

  {
    path: '/slides_iframe_db_chap01',
    name: 'slides_iframe_db_chap01',
    component: slides_iframe_db_chap01,
  },

  {
    path: '/slides_iframe_db_chap02',
    name: 'slides_iframe_db_chap02',
    component: slides_iframe_db_chap02,
  },

  {
    path: '/slides_iframe_db_chap03',
    name: 'slides_iframe_db_chap03',
    component: slides_iframe_db_chap03,
  },

  {
    path: '/slides_iframe_db_chap04',
    name: 'slides_iframe_db_chap04',
    component: slides_iframe_db_chap04,
  },

  {
    path: '/slides_iframe_db_chap05',
    name: 'slides_iframe_db_chap05',
    component: slides_iframe_db_chap05,
  },

  {
    path: '/slides_iframe_db_chap06',
    name: 'slides_iframe_db_chap06',
    component: slides_iframe_db_chap06,
  },

  {
    path: '/slides_iframe_db_chap07',
    name: 'slides_iframe_db_chap07',
    component: slides_iframe_db_chap07,
  },

  {
    path: '/slides_iframe_db_chap08',
    name: 'slides_iframe_db_chap08',
    component: slides_iframe_db_chap08,
  },

  /*
  {
    path: '/slides_iframe_db_dm',
    name: 'slides_iframe_db_dm',
    component: slides_iframe_db_dm,
  },
   */

  {
    path: '/slides_iframe_tc_00overview',
    name: 'slides_iframe_tc_00overview',
    component: slides_iframe_tc_00overview,
  },

  {
    path: '/slides_iframe_tc_01_secboot_signmodule',
    name: 'slides_iframe_tc_01_secboot_signmodule',
    component: slides_iframe_tc_01_secboot_signmodule,
  },

  {
    path: '/slides_iframe_tc_02_bitlocker',
    name: 'slides_iframe_tc_02_bitlocker',
    component: slides_iframe_tc_02_bitlocker,
  },

  /*
  {
    path: '/slides_iframe_test',
    name: 'slides_iframe_test',
    component: slides_iframe_test,
  },
   */
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
