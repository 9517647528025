<template>
  <div class="routerview">
    <iframe class="iframe-slide" src="/slides_iframe_db_chap03"></iframe>
  </div>   
</template>


<script>
    export default {
        name: "slidesdb_chap03"
    }
</script>


<style scoped src="@/assets/css/globalcn.css">
</style>
